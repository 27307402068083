import React, { useState, useEffect, useCallback } from 'react'

const OrionGalacticBackground = () => {
  const [stars, setStars] = useState([])
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  // Function to get the current screen size
  const updateScreenSize = useCallback(() => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, [])

  // Create stars based on screen size
  const createStars = useCallback(() => {
    const newStars = []
    for (let i = 0; i < 300; i++) {
      newStars.push({
        id: i,
        width: Math.random() * 3,
        left: `${Math.random() * screenSize.width}px`,
        top: `${Math.random() * screenSize.height}px`,
        animationDelay: `${Math.random() * 10}s`,
      })
    }
    setStars(newStars)
  }, [screenSize])

  const animateStars = useCallback(() => {
    setStars((prevStars) =>
      prevStars.map((star) => ({
        ...star,
        opacity:
          Math.random() < 0.001 ? (star.opacity === 0 ? 1 : 0) : star.opacity,
      }))
    )
    requestAnimationFrame(animateStars)
  }, [])

  useEffect(() => {
    // Create stars and start animation
    createStars()
    const animationFrame = requestAnimationFrame(animateStars)

    // Update screen size on resize
    window.addEventListener('resize', updateScreenSize)

    return () => {
      cancelAnimationFrame(animationFrame)
      window.removeEventListener('resize', updateScreenSize)
    }
  }, [createStars, animateStars, updateScreenSize])

  return (
    <div className="galaxy-background">
      <div className="stars">
        {stars.map((star) => (
          <div
            key={star.id}
            className="star"
            style={{
              width: `${star.width}px`,
              height: `${star.width}px`,
              left: star.left,
              top: star.top,
              animationDelay: star.animationDelay,
              opacity: star.opacity,
            }}
          />
        ))}
      </div>
      <style>
        {`
          .galaxy-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: radial-gradient(ellipse at center, #0d141c 0%, #090a0f 100%);
            overflow: hidden;
          }
          .stars {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            animation: rotate 200s linear infinite;
          }
          .star {
            position: absolute;
            background-color: #fff;
            border-radius: 50%;
            transition: opacity 1s ease-in-out;
          }
          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  )
}

export default OrionGalacticBackground
