import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './App.css'
import OrionGalacticLogo from './components/SplashScreen'
import ToastManagerProvider, { useToast } from './components/Toast'

function App() {
  const [counter, setCounter] = useState(5)
  const navigate = useNavigate()
  const [inviteCode, setInviteCode] = useState('')

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1)
    }, 1000)
    if (counter === 0) {
      navigate('/home-page')
    }
    return () => clearInterval(timer)
  }, [counter, navigate])

  return (
    <div
      style={{
        overflow: 'hidden',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column', // Arrange items vertically
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <OrionGalacticLogo />

      {/* Centered Image */}
      <div style={{ zIndex: '2' }}>
        <img
          src="/assets/img/orionlogo.png"
          alt="Orion Galactic Logo"
          style={{
            width: '200px',
            height: '200px',
          }}
        />
      </div>

      {/* Text content below the image */}
      <div
        style={{
          display: 'none',
          color: 'white',
          zIndex: 1,
          position: 'relative',
          textAlign: 'center',
        }}
      >
        <h1>Welcome to HashMiner</h1>
        {inviteCode ? (
          <p>Invite Code: {inviteCode}</p>
        ) : (
          <p>No invite code provided.</p>
        )}
      </div>
    </div>
  )
}

export default App
