import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const VerticalStepper = () => {
  return (
    <Stepper
      orientation="vertical"
      activeStep={3}
      style={{
        borderRadius: "10px",
        paddingLeft: "20px",
      }}
      className="custom-stepper"
    >
      <Step>
        <StepLabel StepIconProps={{ className: 'custom-step-icon' }}>
          Share Your Invation Link
        </StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconProps={{ className: 'custom-step-icon' }}>
          Your Friends Join Orion Coin
        </StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconProps={{ className: 'custom-step-icon' }}>
          Get 250 Coin
        </StepLabel>
      </Step>
    </Stepper>
  );
};

const styles = `
  .custom-stepper .MuiStepIcon-root {
    color: #fff;  /* Change this to your desired color */
  }
  .custom-stepper .MuiStepIcon-active {
    color: #2196F3;  /* Change this to your desired active color */
  }
  .custom-stepper .MuiStepIcon-completed {
    color: #FFC107;  /* Change this to your desired completed color */
  }
  .custom-stepper .MuiStepLabel-label {
    color: white;  /* Change the label color to white */
      font-size: 2.5vw; /* تنظیم فونت بر اساس درصدی از عرض صفحه */
    margin-top: 5px;
      font-family: "MyNormalFont", sans-serif;


  }
    @media (min-width: 1200px) {
  .custom-stepper .MuiStepLabel-label {
    font-size: 1.5vw;
  }
}
  /* Media Query برای دستگاه‌های کوچک‌تر */
@media (max-width: 600px) {
  .custom-stepper .MuiStepLabel-label {
    font-size: 3vw;
  }
}
`;

const StyledStepper = () => (
  <>
    <style>{styles}</style>
    <VerticalStepper />
  </>
);

export default StyledStepper;