import React, { useState, useEffect } from 'react'
import ConnectButton from '../components/TonConnect'
import VerticalStepper from '../components/VerticalSteper'
import BottomNavigation from '../components/BottomNavigation'
import SlideBottom from '../components/SlideBottom'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import OrionGalacticLogo from '../components/SplashScreen'
import InviteCodeGenerator from '../utils/InviteCodeGenerator'
import BottomSheet from '../components/BottomSheet'
import CardList from '../components/TopErn'
import WeeklyList from '../components/Weekly'
import Tab from '../components/Tab'
import ToastManagerProvider, { useToast } from '../components/Toast'
import '../App.css'
import '../css/my.css'

function Wallet() {
  return (
    <div>
      <ConnectButton />
    </div>
  )
}

function Page2() {
  return (
    <div
      style={{
        minHeight: '700px',
        marginTop: '0px',
        boxSizing: 'border-box',
        overflowY: 'auto',
        color: 'white',
      }}
    >
      <p
        style={{
          paddingLeft: '0px',
          display: 'inline-block',
          marginTop: '10px',
        }}
        className="card-title"
      >
        Top Earn
      </p>

      <CardList />

      <p
        style={{
          paddingLeft: '0px',
          display: 'inline-block',
          marginTop: '20px',
          marginBottom: '15px',
        }}
        className="card-title"
      >
        Weekly
      </p>
      <WeeklyList />
      <Tab />
    </div>
  )
}

function Page3() {
  const [toastShown, setToastShown] = useState(false)
  const { showToast } = useToast()

  useEffect(() => {
    if (!toastShown) {
      showToast('Data Updated !', 'success')
      setToastShown(true)
    }
  }, [toastShown, showToast])

  return <div style={styles.container}>{/* <CoomingSoon /> */}</div>
}

function InviteFriends() {
  return (
    <div>
      <div>
        <p
          style={{
            color: 'white',
            paddingLeft: '5px',
            display: 'inline-block',
            marginTop: '10px',
          }}
          className="card-title"
        >
          Invite Friends, Earn Coin
        </p>
        <VerticalStepper />
      </div>

      <BottomSheet />
    </div>
  )
}

const styles = {
  container: {
    fontSize: '20px',
    color: 'white',
    fontFamily: 'MyBoldFont',
  },
}

function HomeContent() {
  const [currentPage, setCurrentPage] = useState('page1')

  const renderContent = () => {
    switch (currentPage) {
      case 'page1':
        return <Page3 key="page1" />
      case 'page2':
        return <Page2 key="page2" />
      case 'page3':
        return <InviteFriends key="page3" />
      case 'page4':
        return <Wallet key="page4" />
      default:
        return <Page3 key="page1" />
    }
  }

  const [username, setUsername] = useState('')
  const [userId, setUserId] = useState('')

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const user = window.Telegram.WebApp.initDataUnsafe.user

      if (user && user.username && user.id) {
        setUsername(user.username)
        setUserId(user.id)
      } else {
        setUsername('Username not found')
        setUserId('ID not found')
      }
    } else {
      setUsername('Telegram Web App Is Busy')
      setUserId('Telegram Web App Is Busy')
    }
  })

  return (
    <div className="container">
      <OrionGalacticLogo />
      <div className="header">
        <SlideBottom>
          <div className="containertop">
            <div className="left">
              <img
                src={process.env.PUBLIC_URL + '/assets/img/cup.svg'}
                alt="level"
                className="nav-icon"
              />
              <span id="username" className="username">
                {username}
              </span>
            </div>
            <div className="right" style={{ marginRight: '5px' }}>
              <img
                src={
                  process.env.PUBLIC_URL + '/assets/img/coinearnselected.svg'
                }
                alt="coin"
                className="nav-icon"
              />
              <span className="coin">11.000.000</span>
            </div>
          </div>
        </SlideBottom>
      </div>
      <SlideBottom key={currentPage}>
        <div className="content">{renderContent()}</div>
      </SlideBottom>

      <div className="footer">
        <BottomNavigation setCurrentPage={setCurrentPage} />
      </div>
    </div>
  )
}

function Home() {
  return (
    <TonConnectUIProvider manifestUrl="https://raw.githubusercontent.com/howardpen9/test-twa-Oct-2023/refs/heads/main/tonconnect-manifest.json">
      <ToastManagerProvider>
        <HomeContent />
      </ToastManagerProvider>
    </TonConnectUIProvider>
  )
}

export default Home
