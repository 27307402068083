import React, { useState, useEffect } from "react";
import "../App.css";

function BottomNavigation({ setCurrentPage, initialSelectedItem }) {
  const [selectedItem, setSelectedItem] = useState(initialSelectedItem || "page1");

  useEffect(() => {
    // ذخیره مقدار selectedItem در localStorage
    localStorage.setItem("selectedItem", selectedItem);
  }, [selectedItem]);

  const handleItemClick = (page) => {
    if (selectedItem !== page) {
      setSelectedItem(page);
      setCurrentPage(page);
    }
  };

  const navItems = [
    {
      id: "page1",
      text: "Earn",
      icon: "/assets/img/coinern.svg",
      selectedIcon: "/assets/img/coinearnselected.svg",
      alt: "Earn",
      objectFit: "fill",
    },
    {
      id: "page2",
      text: "Task",
      icon: "/assets/img/task.svg",
      selectedIcon: "/assets/img/taskselected.svg",
      alt: "Task",
      objectFit: "fill",
    },
    {
      id: "page3",
      text: "Friends",
      icon: "/assets/img/friendsbottom.svg",
      selectedIcon: "/assets/img/friendsbottomselected.svg",
      alt: "Friends",
      objectFit: "fill",
    },
    {
      id: "page4",
      text: "Wallet",
      icon: "/assets/img/newwallet.svg",
      selectedIcon: "/assets/img/newwalletselected.svg",
      alt: "Wallet",
      objectFit: "fill",
    },
  ];

  return (
    <div className="bottom-navigation">
      {navItems.map((item) => (
        <div
          key={item.id}
          className={`nav-item ${selectedItem === item.id ? 'selected' : ''}`}
          onClick={() => handleItemClick(item.id)}
        >
          <img
            src={selectedItem === item.id ? item.selectedIcon : item.icon}
            alt={item.alt}
            className={selectedItem === item.id ? "selected" : ""}
            style={{ objectFit: item.objectFit }}
          />
          <span>{item.text}</span>
        </div>
      ))}
    </div>
  );
}

export default BottomNavigation;