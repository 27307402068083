import React, { useState } from 'react'
import '../css/my.css'

const Weekly = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const cards = [
    {
      title: 'Card 1',
      description: 'This is the first card.',
      earn: '110 Coin',
    },
    {
      title: 'Card 2',
      description: 'This is the second card.',
      earn: '20 Coin',
    },
    {
      title: 'Card 3',
      description: 'This is the third card.',
      earn: '130 Coin',
    },
    {
      title: 'Card 4',
      description: 'This is the fourth card.',
      earn: '120 Coin',
    },
  ]

  const handleButtonClick = (index) => {
    alert(`Button on card ${index + 1} clicked!`)
  }

  return (
    <div className="slider-container">
      <div className="slider">
        <div
          className="card-list"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {cards.map((card, index) => (
            <div className="cardWeekly" key={index}>
              <button
                className="top-right-button"
                onClick={() => handleButtonClick(index)}
              >
                {card.earn}
              </button>
              <p className="card-title">{card.title}</p>
              <p className="card-description">{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Weekly
