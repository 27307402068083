// TonConnectButton.js
import React, { useCallback, useState } from "react";
import { useTonConnectUI } from "@tonconnect/ui-react";

const TonConnectButton = ({ onShowSnackbar }) => {
  const [tonConnectUI] = useTonConnectUI();
  const [isLoading, setIsLoading] = useState(false);

  const handleConnection = useCallback(async () => {
    setIsLoading(true);
    try {
      if (tonConnectUI.connected) {
        await tonConnectUI.disconnect();
      } else {
        await tonConnectUI.connectWallet();
      }
    } catch (err) {
      console.error("Connection error:", err);
      const errorMessage =
        err.message === "Operation aborted"
          ? "اتصال لغو شد. لطفاً دوباره تلاش کنید."
          : "خطا در اتصال. لطفاً دوباره تلاش کنید.";
    } finally {
      setIsLoading(false);
    }
  }, [tonConnectUI]);

  const getWalletName = () => {
    return tonConnectUI.account?.address ?? "کیف پول ناشناخته";
  };

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  return (
    <div>
      <button
        onClick={handleConnection}
        className="button"
        disabled={isLoading}
      >
        <img src="/assets/img/wallets.svg" alt="Icon" className="icon" />
        <span>
          {isLoading
            ? "Connecting . . ."
            : tonConnectUI.connected
            ? truncateString(getWalletName(), 20)
            : "Connect Wallet"}
        </span>
      </button>
    </div>
  );
};

export default TonConnectButton;
