import React, { useState, useRef, useEffect } from "react";
import { Sheet } from "react-modal-sheet";
import { X } from "lucide-react";

const InviteFriends = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);

  const openBottomSheet = () => {
    setIsOpen(true);
  };

  const closeBottomSheet = () => {
    setIsOpen(false);
  };

  const sheetContainerStyle = {
    background: "rgb(11 14 16)",
    color: "white",
    borderRadius: "20px 20px 0 0",
    borderTop: "1px solid rgb(255 255 255 / 8%)",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.3)",
    position: "fixed",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "MyNormalFont",
  };

  const closeIconStyle = {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
    background: "none",
    border: "none",
    zIndex: 10,
  };

  return (
    <>
      <style>
        {`
          @keyframes glowing {
            0% {
              box-shadow: 0 0 5px rgba(255, 255, 255, 0.5),
                          0 0 10px rgba(255, 255, 255, 0.3),
                          0 0 15px rgba(255, 255, 255, 0.1);
            }
            50% {
              box-shadow: 0 0 10px rgba(255, 255, 255, 0.5),
                          0 0 20px rgba(255, 255, 255, 0.3),
                          0 0 30px rgba(255, 255, 255, 0.1);
            }
            100% {
              box-shadow: 0 0 5px rgba(255, 255, 255, 0.5),
                          0 0 10px rgba(255, 255, 255, 0.3),
                          0 0 15px rgba(255, 255, 255, 0.1);
            }
          }
        `}
      </style>
      <div>
        <button
          className="button"
          style={{
            position: "fixed",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            width: "99%",
            justifyContent: "center",
          }}
          onClick={openBottomSheet}
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/img/user.svg"}
            alt="coin"
            className="nav-icon"
            style={{ marginRight: "20px" }}

          />
          Invite Friend
        </button>

        <Sheet
          disableDrag={true}
          isOpen={isOpen}
          onClose={closeBottomSheet}
          snapPoints={[contentHeight, 0]}
          initialSnap={0}
        >
          <Sheet.Container style={sheetContainerStyle}>
            <button onClick={closeBottomSheet} style={closeIconStyle}>
              <X size={24} color="white" />
            </button>
            <Sheet.Content style={{ flex: 1, overflowY: "auto" }}>
              <div
                ref={contentRef}
                style={{
                  padding: "20px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h2 style={{
                    color: "white", marginBottom: "40px",
                  }}>Invite Your Friends</h2>
                </div>
                <div >
                  <button
                    className="button"
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      justifyContent: "center",

                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/copy.svg"}
                      alt="coin"
                      className="nav-icon"
                      style={{ marginRight: "20px" }}

                    />

                    Copy Invitation Link
                  </button>
                  <button
                    className="button"
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      marginBottom: "10px",

                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/share.svg"}
                      alt="coin"
                      className="nav-icon"
                      style={{ marginRight: "20px" }}
                    />

                    Share Invitation Link

                  </button>
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop
            style={{ background: "rgba(0, 0, 0, 0.7)" }}
          />
        </Sheet>
      </div>
    </>
  );
};

export default InviteFriends;